import axios from "@/plugins/axios";
import * as v2 from "./v2";

let url = "/api/plugins/coampay";

export const coampay = {
    ...v2,
    
    config: {
        get(){
            return axios.get(`${url}/config`).then(result => result.data);
        },
    },
    generate(data){
        return axios.post(`${url}/generate`, data).then(result => result.data);
    },
}
